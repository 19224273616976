button, .button, a.button {
  background: var(--brand-lighten);
  border: 1px solid var(--brand-lighten);
  color: var(--white);
  padding: 20px;
  border-radius: 8px;
  text-transform: uppercase;
  font-size: 15px;
  transition: .1s background-color ease-in-out;
  cursor: pointer;

  &:hover {
    background: var(--brand-lighten-super);
    border: 1px solid var(--brand-lighten);
  }

  &--disabled, &:disabled {
    cursor: default;
    opacity: .5;

    &:hover {
      background: initial;
      background: var(--brand-lighten);
    }
  }

  &--close {
    color: var(--text);
    background: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: .1s opacity ease-in-out;
    opacity: .5;

    &:hover {
      background: transparent;
      opacity: 1;
      border: none;
    }
  }

  &--max-width {
    max-width: 100%;
    width: 100%;
  }

  &--center {
    margin: 0 auto;
    padding: 1em 4em;
  }
}