@import "static/scss/variables.scss";

.documentation {
  margin-top: 20px;
  border-radius: 4px;

  h1, h2, h3, p {
    padding: 5px 0;
    margin: 0;
  }

  svg {
    max-width: 100px;
  }

  .menu-content {
    z-index: 60;
    border-radius: 4px;
  }

  .api-content {
    overflow: hidden;

    + div {
      background: var(--white);
      border-radius: 4px;
    }

    > div {
      margin: 0;
      padding: 30px 0 0;
    }
  }

  @media (max-width: 85rem) {
    div {
      padding: 0;
    }
  }

  label {
    background: transparent;
    border: transparent;
  }

  h1, h2 {
    a {
      border-bottom: none;
    }
  }

  // Nice CSS hack to remove the Redoc ref ;)
  a[href="https://github.com/Rebilly/ReDoc"] {
    display: none !important;
  }

  [role='search'] input {
    padding: 20px;
  }

  [role='search'] svg,
  [role='search'] i {
    top: 20px;
  }
}