.paymentForm {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background: var(--white);
    border-radius: 4px;
    padding: 1em;
    color: var(--black);
    min-width: 20em;
    text-align: left;

    &__confirm-list {
        padding: 0 1em 1em;
        margin: 0;
        color: var(--gray);
        font-weight: 100;
        font-size: smaller;
    }

    &__title {
        margin-bottom: .5em;
    }

    &__button {
        margin-top: 1em;
    }
}