@import "static/scss/variables.scss";

.subnav {
  display: flex;
  justify-content: flex-end;
  padding: 1em 1em 0 0;

  .button--subnav {
    padding: 0.5em;
    margin: 0 0.5em;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (min-width: $breakpoint-desktop) {
    padding: 1em 0 0;
  }
}
