.ReactModal__Overlay {
  z-index: 999;
  background: rgba(0, 0, 0, 0.75) !important;
}

.ReactModal__Content {
  background: var(--off-white);
  padding: var(--container-padding);
  max-width: 900px;
  margin: var(--container-padding) auto var(--container-padding) auto;
  position: relative;

  .container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
