@import "static/scss/variables.scss";

nav {
  overflow: hidden;
  display: grid;
  align-items: center;
  top: 0;
  background-color: var(--brand-base);
  border-bottom: 1px var(--black) solid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-gap: 1em;
  text-align: center;
  max-width: var(--container);
  margin: 0 auto;
  z-index: 20;

  ul {
    margin: 0;
    padding: 0;
    display: grid;
    transition: 0.4s cubic-bezier(0.445, -0.24, 0.355, 1.005) all;
    position: absolute;
    right: 0;
    top: 79px;

    grid-template-columns: 100%;
    grid-template-rows: auto;
    text-align: center;

    transform: translateX(100%);
    transform-origin: top;
    opacity: 0;
    z-index: 9;

    &.open {
      transform: translateX(0);
      opacity: 1;
      z-index: 100;
    }

    li {
      line-height: 28px;
      list-style: none;
      background-color: var(--brand-lighten);
      padding: 8px 50px;
      margin-top: 1px;
      transition: ease-in all 0.05s;
      cursor: pointer;
      text-align: left;
      width: 100vw;
      padding-left: 1em;

      @media (min-width: $breakpoint-desktop) {
        text-align: right;
        padding-left: 50px;
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
        padding-right: 0;
      }

      a {
        padding-bottom: 5px;
        border-bottom: 1px solid transparent;

        &:hover {
          border-bottom: 1px solid transparent;
        }
      }

      &.active {
        @media (min-width: $breakpoint-desktop) {
          a {
            border-bottom: 1px solid var(--brand-lighten);
          }
        }
      }

      &:hover {
        background: var(--brand-lighten-super);

        @media (min-width: $breakpoint-desktop) {
          a {
            border-bottom: 1px solid var(--brand-lighten);
          }
        }
      }
    }
  }

  .hamburger {
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 16px;
    font-size: 30px;
    z-index: 9;
    z-index: 100;
  }

  .logo {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 25px;
    text-decoration: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 80;

    label {
      margin-left: 10px;
    }
  }

  @media (min-width: $breakpoint-desktop) {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    border-bottom: none;

    .hamburger {
      display: none;
    }

    ul {
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: auto;
      transform: translateX(0);
      opacity: 1;
      position: relative;
      top: initial;

      li {
        background-color: transparent;
        padding: 0 20px;
        text-align: center;
        text-shadow: 0 0 0 var(--brand-lighten);

        &:hover {
          background: none;
          text-shadow: 1px 5px 10px var(--black);
        }
      }
    }

    .logo {
      margin-right: auto;
      padding: 25px 0;
    }
  }
}
