form {
  label {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    margin: 15px 0 0 0;
    padding: 0;
    display: grid;
    grid-gap: 5px;
    grid-column: 1 / 3;
    color: var(--text);
  }

  input,
  select {
    width: 100%;
    padding: 10px;
    border-radius: 80px;
    outline: 0;
    border: 0;
    -webkit-appearance: none;
    border: 1px solid var(--light-grey);
  }

  .select-wrapper {
    overflow: hidden;
    width: 100%;
    background: url("data:image/png;base64,R0lGODlhDwAUAIABAAAAAP///yH5BAEAAAEALAAAAAAPABQAAAIXjI+py+0Po5wH2HsXzmw//lHiSJZmUAAAOw==")
      no-repeat 96% 49%;
    background-color: white;
    border-radius: 80px;

    select {
      width: 100%;
      max-width: 100%;
      background: transparent;
    }
  }

  input.button {
    margin-top: 20px;
    grid-column: 1 / 3;
  }

  @media (min-width: $breakpoint-tablet) {
    padding: 0;

    label {
      grid-column: inherit;
    }

    input,
    select {
      width: auto;
      min-width: 200px;
      padding: 10px;
    }
  }
}
