@import "static/scss/variables.scss";

.info-card {
  color: var(--black);
  border-bottom: 1px solid var(--off-grey);
  padding: 20px var(--container-padding);
  font-weight: var(--font-weight-light);

  &:last-child {
    border-bottom: none;
  }

  span {
    float: left;
    width: 100%;
    font-weight: var(--font-weight-bold);
  }

  @media (min-width: $breakpoint-tablet) {
    border-bottom: 0;
    padding: 20px;

    span {
      border-bottom: 1px solid var(--brand-lighten);
      padding: 0 0 5px 0;
      margin: 0 0 10px 0;
    }
  }

  @media (min-width: $breakpoint-desktop) {
    border: none;
    padding: 20px var(--container-padding) 0 20px;
  }
}
