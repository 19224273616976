@import "static/scss/variables.scss";

.code-preview {
  // background: var(--off-white);
  color: var(--text);
  display: grid;
  grid-template-areas:
    "code-description code-description"
    "code-header code-header"
    "code code";

  &.hide {
    display: none;
  }

  &__container {
    display: block;
    max-width: 900px;
    margin: auto;
  }

  &__header {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    align-items: center;
    grid-area: code-header;
    color: var(--white);
    font-size: 14px;

    strong {
      margin-right: 20px;
    }

    @media (min-width: $breakpoint-tablet) {
      justify-content: flex-start;
    }
  }

  pre {
    margin: 0;
    grid-area: code;
  }

  &__content {
    grid-area: code-description;
    border-bottom: 1px solid var(--gray);
    padding-bottom: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  strong {
    background: var(--brand-lighten);
    color: white;
    padding: 4px 8px;
    border-radius: 5px;
    font-size: 12px;
  }

  @media (min-width: $breakpoint-desktop) {
    padding: 40px 0 60px 0;
    text-align: left;
    background: transparent;
    grid-template-areas:
      "code-header code-description"
      "code code-description";

    pre {
      width: 500px;
    }

    &__content {
      align-items: flex-start;
      border: none;
      padding-left: 40px;
      justify-content: flex-start;
      padding-top: 2em;
    }
  }

  @media (min-width: $breakpoint-desktop-large) {
    pre {
      width: 700px;
    }
  }
}
