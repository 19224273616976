@import "static/scss/variables.scss";

.sign-up {
  h1 {
    margin-bottom: 10px;
    width: 100%;
    color: var(--white);
    margin-top: 60px;
    text-align: center;

    @media (min-width: $breakpoint-tablet) {
      text-align: left;
    }
  }
}

.sign-up-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  width: 100%;
  max-width: 700px;

  .error {
    text-align: left;
    width: 100%;
    grid-column: 1 / 3;
    font-size: 15px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .success {
    text-align: left;
    width: 100%;
    grid-column: 1 / 3;
    font-size: 15px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .agree-checkbox {
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;

    input {
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin-right: 10px;
      transition: .1s ease-in-out background;
      cursor: pointer;

      &:checked {
        background: var(--brand-lighten);
      }
    }
  }

  [type=submit] {
    margin: 0;
  }
}