.preview-code-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: var(--container-padding) 0;

  // @TODO: make mobile better instead of overflow hidden
  width: 100%;
  overflow: hidden;

  &__item {
    &.hide {
      display: none;
    }
  }

  .select-code-preview {
    display: flex;
    justify-content: center;

    &__item {
      margin: 10px;
      background: transparent;

      &--selected {
        background: var(--brand-lighten);
      }
    }
  }
}