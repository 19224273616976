@import "static/scss/variables.scss";

.info-cards {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;

  h2 {
    margin: 0;
  }

  @media (min-width: $breakpoint-tablet) {
    grid-template-columns: 1fr 1fr 1fr;

    align-items: flex-start;
  }

  @media (min-width: $breakpoint-desktop) {
    padding: var(--container-padding) 0;
    grid-gap: 40px;
  }
}