@import "./motion.scss";
@import "./variables.scss";
@import "./modal.scss";
@import "./button.scss";
@import "./form.scss";

:root {
  --black: black;
  --white: white;
  --gray: #979797;
  --off-white: #f7f7f7;
  --off-grey: #d7d3cc;
  --light-grey: #efece8;
  --red: #e74c3c;
  --green: #2ecc71  ;

  --brand-base: #1b1464;
  --brand-lighten: #9980fa;
  --brand-lighten-super: #ccc0fa;

  --text: var(--black);
  --text-invert: var(--off-white);
  --font: "Metropolis", sans-serif;
  --error: var(--red);
  --success: var(--green);

  --container-padding: 40px;
  --container: 900px;

  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-bold: 600;
}

*,
*::before,
*::after {
  font-family: var(--font);
  box-sizing: border-box;
}

@media (min-width: $breakpoint-desktop) {
  :root {
    --container-padding: 80px;
  }

  html {
    font-size: 20px;
  }
}

@media (min-width: $breakpoint-desktop-large) {
  :root {
    --container: 1200px;
  }
}


html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font);
  font-size: 1rem;
  line-height: 1.5;
  background-color: var(--brand-base);
  color: var(--text-invert);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#__next {
  position: relative;
  overflow: hidden;
}

h1 {
  margin: 0 auto 7.5vmin;
  font-weight: normal;
  line-height: 1.25;
  color: var(--brand-base);
  font-size: 25px;
  margin-bottom: 15px;

  @media (min-width: $breakpoint-tablet) {
    font-size: 35px;
    margin-bottom: 25px;
  }
}

.heading {
  &--white {
    color: var(--brand-white);
  }
}

p {
  margin: 0 0 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  border-bottom: 1px solid var(--brand-lighten);
  color: currentColor;
  text-decoration: none;
  transition: border-color $d-fast $ease-in-out;

  &:focus {
    outline: 1px solid var(--brand-lighten);
    outline-offset: 2px;
  }

  &:hover {
    border-bottom-color: currentcolor;
  }
}

.main {
  background: var(--white);
}

.container {
  max-width: var(--container);
  margin: 0 auto;

  @media (min-width: var(--breakpoint-desktop)) {
    padding: var(--container-padding);
  }
}

.error {
  color: var(--error);
}

.success {
  color: var(--success);
}

.background {
  &--gray {
    background: var(--off-white);

    p {
      color: var(--text);
    }
  }

  &--brand {
    background: var(--brand-base);
    border-top: var(--brand-base) 1px solid;
    border-bottom: var(--brand-base) 1px solid;

    p, h1, h2, a {
      color: var(--white);
    }
  }
}

.container-padding {
  padding: var(--container-padding);
}

.light--text {
  color: var(--white);

  h1, h2, label {
    color: var(--white);
  }
}

.dark--text {
  color: var(--black);
}

.recurring-user-text {
  font-size: .8em;
  color: var(--light-grey);
  margin-top: .8em;
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis-SemiBold"), local("Metropolis-SemiBold"),
    url("/fonts/Metropolis-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis Regular"), local("Metropolis-Regular"), url("/fonts/Metropolis-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis Light"), local("Metropolis-Light"), url("/fonts/Metropolis-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
