@import "static/scss/variables.scss";

.header {
  padding: var(--container-padding);
  position: relative;
  overflow: hidden;
  z-index: 10;

  &__background {
    display: none;
  }

  section {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 2;
    position: relative;

    &.align-left {
      @media (min-width: $breakpoint-tablet) {
        align-items: flex-start;
      }
    }
  }

  &.sign-up {
    section {
      align-items: flex-start;
    }
  }

  h1 {
    font-size: 40px;
    text-align: center;
    color: var(--white);
    margin-bottom: 30px;
  }

  &__background {
    height: 100%;
    position: absolute;
    bottom: 0;
    max-width: var(--container);
    margin: 0 auto;
    left: 50%;
    margin-left: -550px;
    z-index: 1;
  }

  @media (min-width: $breakpoint-tablet) {
    h1 {
      max-width: 600px;
      text-align: left;
      align-self: flex-start;
      margin: 0 0 40px 0;
    }

    .button {
      align-self: flex-start;
    }

    &__background {
      display: block;
    }
  }

  @media (min-width: $breakpoint-desktop) {
    padding: 130px var(--container-padding);

    &.sign-up {
      padding: 50px var(--container-padding) 100px var(--container-padding);
    }
  }
}
