@import "static/scss/variables.scss";

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: flex-end;
  padding: 10px var(--container-padding);
  border-top: 1px solid rgba(173, 162, 224, .2);

  &__extra {
    text-align: center;
    grid-column: 1 / -1;
    padding-bottom: 20px;
  }

  &__logo {
    border: none;
  }

  &__menu {
    display: grid;
    align-items: center;

    ul {
      text-align: right;

      li {
        display: inline-block;
        padding: 0 10px;
        font-size: 14px;
      }
    }
  }

  @media (min-width: $breakpoint-desktop) {
    padding: 10px 0;
  }
}