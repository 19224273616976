@import "static/scss/variables.scss";

.content {
  padding: var(--container-padding);
  display: flex;
  text-align: center;
  flex-direction: column;

  &--left {
    text-align: left;
    align-items: flex-start;
    padding: 0;
  }

  @media (min-width: $breakpoint-tablet) {
    justify-content: center;
    align-items: center;

    &--left {
      align-items: flex-start;
    }
  }
}