.modal--subscribe {
  color: var(--black);

  p {
    text-align: left;
    width: 100%;
  }
}

.select-plan {
  h1 {
    color: white;
    text-align: left;
    margin-top: var(--container-padding);
    margin-bottom: 50px;
  }
}

.pricing-table {
  color: var(--text);
  padding: 0 0 var(--container-padding) 0;
}

.pricing-table h2 {
  font-size: 1.5em;
  padding: 35px 10px;
  margin: 0;
}

.description {
  margin-top: 20px;
  font-size: 0.85em;
  text-align: left;
  padding: 2em 40px 0 40px;
  margin: 0;
}

.pricing-box {
  -webkit-transition: all ease-out 0.2s;
  transition: all ease-out 0.2s;
  background: var(--white);
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    li {
      text-decoration: none;
      list-style: none;
    }
  }

  .button {
    width: 100%;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.price {
  font-size: 1em;
  font-weight: 600;
  position: relative;
  padding: 0 10px;
  display: block;
}

.pricing-table-divider {
  display: block;
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  max-width: 80%;
  margin: 20px auto 0;
}

.pricing-table {
  border-radius: 3px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  text-align: center;
}

.pricing-box {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.13);
  border: 2px solid rgba(10, 123, 183, 0.14);

  // &--preferred {
  //   background: var(--brand-lighten);
  // }
}

.pricing-table ul li {
  padding: 3px 0;
  font-size: 0.95em;
}

.pricing-table ul {
  color: #000;
  text-align: left;
}

@media (max-width: 900px) {
  .select-plan {
    padding: 0 var(--container-padding);
  }

  .pricing-table {
    padding: 20px var(--container-padding);
  }
}

@media (max-width: 750px) {
  .pricing-table {
    grid-template-columns: 1fr;
  }

  .pricing-table > div:nth-child(3) {
    grid-row-start: 1;
  }

  .pricing-table > div:nth-child(2) {
    grid-row-start: 2;
  }

  .pricing-table > div {
    align-self: top;
  }
}
