.dashboard {
  h1 {
    font-size: 55px;
    color: var(--white);
    margin-bottom: var(--container-padding);
  }

  h3 {
    display: flex;
    align-items: center;

    label {
      margin-right: 10px;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__card {
    border-radius: 8px;
    padding: 20px;
    background: var(--white);
    color: var(--black);
  }

  &__api-key {
    font-weight: strong;
    margin-bottom: var(--container-padding);
  }

  &__stats {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: var(--container-padding);

    &__graph {
      text-align: center;
      color: gray;
      opacity: .5;
      text-transform: uppercase;
    }
  }
}
